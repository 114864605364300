import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CJumbotron,
  CBreadCrumb,
  LWrap2,
  CSectTitle,
  CColorCard,
  CLabelCard,
  LRecruitUniqueSect,
  CVideoMedia,
  CVideo,
  AssetImage,
  CRecruitJumbtron,
  CTextImgCard,
  RecruitNews,
  Vertical,
  CBtnList,
  CHeroMedia,
  CHotelList,
  ContainerCrumbs
} from "../../../components/_index";
import { useMobile } from '../../../utils/use-series';
import { size } from "lodash";
import { title } from "process";
import '../../../assets/_sass/page/recruit/about.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "三菱地所ホテルズ＆リゾーツについて",
      src: "/recruit/about/",
    },
  ];

  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false} />
      <section className="l_sect05 u_bgWhite recruit-font">
        <LWrap2>
          <ContainerCrumbs data={crumbsData} />
          <div className="r-about-head-container mmb-10">
            <div className="r-about-head-left">
              <div className="r-about-head-jp">三菱地所ホテルズ＆<br className="u_sp" />リゾーツについて</div>
              <div className="r-about-head-en">About Us</div>
            </div>
            <div className="r-about-head-right">
              {!isSp ? <div className="r-about-head-img-container">
                <AssetImage src="/assets/images/recruit/about/title.png"
                  alt=""
                  loading="lazy" />
                <AssetImage src="/assets/images/recruit/about/title_02.png"
                  alt=""
                  loading="lazy" />
                <AssetImage src="/assets/images/recruit/about/title_03.png"
                  alt=""
                  loading="lazy" />
              </div> :
                <div className="r-about-head-img-container">
                  <AssetImage src="/assets/images/recruit/img_05_sp.png"
                    alt=""
                    loading="lazy" />
                  <AssetImage src="/assets/images/recruit/img_06_sp.png"
                    alt=""
                    loading="lazy" />
                  <AssetImage src="/assets/images/recruit/img_07_sp.png"
                    alt=""
                    loading="lazy" />
                </div>
              }

              <div className="r-about-head-article">
                <span className="r-about-head-article-title"> ありたい人財像「6つの力」</span>
                <div className="r-about-head-article-03">
                  <span className="r-about-head-article-content">ホテル事業において、一番大切な資産 は「人財」です。目まぐるしく変化する事業環境の中でしなやかな成長を実現していく<br className="u_pc" />
                    ために、ありたい人財像を「6つの力」で定義しています。この「6つの力」を意識しながら、仕事や人との出会いを通して気づきや<br className="u_pc" />
                    学びを肌で感じ、一人の人間として成長していくことを目指します。</span>
                </div>
                <div className="r-about-head-article-03">
                  <span className="r-about-head-art-num">1</span><span className="r-about-head-article-title2">人を想う力<br className="u_sp" />
                    <span className="r-about-head-article-title3">　〜”一瞬(とき)”と”感動”を共有する</span></span>
                  <div className="r-about-head-article-04">
                    <span className="r-about-head-article-content">人を観て、声を聴き、気持ちを察し、人を想うこころを大切にします。<br />
                      そして、ロイヤルパークホテルズにかかわるすべての人と豊かな一瞬をともに過ごしたい。</span>
                  </div>
                </div>
                <div className="r-about-head-article-03">
                  <span className="r-about-head-art-num">2</span><span className="r-about-head-article-title2">学ぶ力<br className="u_sp" />
                    <span className="r-about-head-article-title3">　〜おもてなしのプロとして常に自己研鑽</span></span>
                  <div className="r-about-head-article-04">
                    <span className="r-about-head-article-content">ロイヤルパークホテルズで働くことの誇りを胸に、常に学び成長し続けます。<br />
                      そして、お客様への”良い加減”のサービスを追い求め、CS（お客様満足）とCD（お客様の喜び）を高いレベルで実現します。</span>
                  </div>
                </div>
                <div className="r-about-head-article-03">
                  <span className="r-about-head-art-num">3</span><span className="r-about-head-article-title2">共に働く力<br className="u_sp" />
                    <span className="r-about-head-article-title3">　〜チームワークで、1＋1を3にする</span></span>
                  <div className="r-about-head-article-04">
                    <span className="r-about-head-article-content">ひとりで出来ないことも、チームならできる。<br />
                      ともに働く仲間を自ら助け、チームワークを発揮することで、組織の力を何倍にも高めます。</span>
                  </div>
                </div>
                <div className="r-about-head-article-03">
                  <span className="r-about-head-art-num">4</span><span className="r-about-head-article-title2">ビジネスする力<br className="u_sp" />
                    <span className="r-about-head-article-title3">　〜お客様とのWIN-WINを実現しビジネスを持続する</span></span>
                  <div className="r-about-head-article-04">
                    <span className="r-about-head-article-content">コンプライアンスの遵守をベースとして、冷静で柔軟な判断力を持ち、<br />
                      お客様の利益と会社の利益の両立を、足し算・引き算を織り交ぜながら実現します。</span>
                  </div>
                </div>
                <div className="r-about-head-article-03">
                  <span className="r-about-head-art-num">5</span><span className="r-about-head-article-title2">考え抜く力<br className="u_sp" />
                    <span className="r-about-head-article-title3">　〜自ら考え、行動する</span></span>
                  <div className="r-about-head-article-04">
                    <span className="r-about-head-article-content">いままでの常識を疑い、自由な発想を大切にします。<br />
                      指示待ちではなく、主体的に行動します。</span>
                  </div>
                </div>
                <div className="r-about-head-article-03">
                  <span className="r-about-head-art-num">6</span><span className="r-about-head-article-title2">未来をつくる力<br className="u_sp" />
                    <span className="r-about-head-article-title3">　〜変化の先に未来あり　恐れず、チャレンジ</span></span>
                  <div className="r-about-head-article-04">
                    <span className="r-about-head-article-content">一歩踏み出した人には新しい世界が見える。<br />
                      ありたい姿を創造するために恐れず踏み出そう。<br />
                      そして、ホテル業界のフロンティアに立ち続けよう。</span>
                  </div>
                </div>
                <div className="r-about-head-article-05">
                  <span className="r-about-head-article-title">職場風土</span>
                  <div className="r-about-head-article-06">
                    <span className="r-about-head-article-content">当社が目指すべき職場風土として、「働きがい、やりがいのある職場づくり」のため、5項目を定めています。<br />
                      ＊ あたたかくて安心できる職場体温<br />
                      ＊ あふれる学びの場<br />
                      ＊ 誰もがキーパーソン<br />
                      ＊ 何かやってくれる、何か変えてくれると思われる”人”と”チーム”<br />
                      ＊ 認める文化・褒める文化 </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="u_bgGray_new recruit-font">
        <LWrap2>
          <div className="yaso-area u_bgGray_new">
            <CHeroMedia data={[
              {
                exClass: 'mt-0-sp mb-40-sp',
                img: {
                  src: '/assets/images/recruit/about/about.png',
                  alt: ''
                },
                imgSp: {
                  src: '/assets/images/recruit/about/about_sp.png',
                  alt: ''
                },
                title: {
                  en: <>VISION <br className="u_pc"/>
                    & <br className="u_pc"/>
                    CREDO</>,
                  ja: <>幸せのピースになる</>
                },
                text: <>人生は、まるでジグソーパズルのようです。<br />
                  華やかな笑顔、ふと感じる幸せ、ひとすじの涙、癒やされるひととき。<br />
                  ひとつひとつのピースがつながり、かけがえのない人生を彩っています。<br />
                  <br />
                  「幸せのピースになる」それが私たちの願いです。<br />
                  <br />
                  お客様や共に働く仲間の⽴場で考え、「⼼地よい加減」で寄り添います。<br />
                  その⼟地を想い「街と、もてなす。」ことで、幸せな時間、⾵景、体験を届けます。<br />
                  そして、皆さまとともに「幸せのピースになる」ことを⽬指します。</>,
              },
              {
                exClass: 'mb-40-sp',
                img: {
                  src: '/assets/images/recruit/about/about_02.png',
                  alt: ''
                },
                imgSp: {
                  src: '/assets/images/recruit/about/about_02_sp.png',
                  alt: ''
                },
                title: {
                  en: <>VALUE</>,
                  ja: <>街と、もてなす。</>
                },
                text: <>人々が集まり、暮らしや営みの中で文化が根づき、<br />
                  それぞれの街の魅力が育まれています。<br />
                  <br />
                  私たちは、その土地を想い、地域に寄り添い、地元に愛されることで「街と、もてなす。」を実現します。<br />
                  <br />
                  暮らす人たちの憩いの場として、<br />
                  訪れる人たちの旅の拠点として、<br />
                  街を楽しむ刺激と心地よい癒しを届けていきます。</>,
              },
              {
                exClass: '',
                img: {
                  src: '/assets/images/recruit/about/about_03.png',
                  alt: ''
                },
                imgSp: {
                  src: '/assets/images/recruit/about/about_03_sp.png',
                  alt: ''
                },
                title: {
                  en: <>SERVICE <br className="u_pc"/>
                    STANDARD</>,
                  ja:<>心地よい加減</>
                },
                text: <>私たちは、学びを深め、新しいことへ恐れず挑戦し続けます。<br />
                プロのホテリエとして、相手の立場に立ち、共に働く仲間と<br />
                お客様に寄り添う「心地よい加減」のサービスを提供します。</>,
              },
            ]}
             
            />

            <div className="about-tringle">
              <div className="about-tringle-inside">
              <AssetImage  src="/assets/images/recruit/about/about_04.png" alt=""/>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect08 u_bgWhite recruit-font">
        <LWrap2>
          <div className="hotel-list-area">
            <div className="yaso-title">ホテル一覧</div>
            <div className="about-hotel-text">宿泊・料飲・宴会機能が揃うフルサービス型ホテル「ロイヤルパークホテル(東京・日本橋)・横浜ロイヤルパークホテル・仙台ロイヤルパークホテル」、<br />
              プレミアム宿泊主体型ホテルの「THE シリーズ」を中心に運営しています。<br />
              ※エリア別採用を実施しており、希望しない全国転勤はありません。<br />
              ※エリア内でのホテル異動はあります。<br /></div>

            <div className="r-about-hotel-name">札幌エリア</div>
            <CHotelList addresses={[
              {
                name: "ザ ロイヤルパーク キャンバス 札幌大通公園",
                address: "〒060-0042　札幌市中央区大通西1-12",
                link: "https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/"
              }
            ]} />

            <div className="r-about-hotel-name">仙台エリア</div>
            <CHotelList addresses={[
              {
                name: "仙台ロイヤルパークホテル",
                address: "〒981-3204　仙台市泉区寺岡6-2-1",
                link: "https://www.srph.co.jp/"
              }
            ]} />

            <div className="r-about-hotel-name">東京・横浜エリア</div>
            <CHotelList addresses={[
              {
                name: "ロイヤルパークホテル（東京・日本橋）",
                address: "〒103-8520　東京都中央区日本橋蛎殻町2-1-1",
                link: "https://www.rph.co.jp/"
              },
              {
                name: "横浜ロイヤルパークホテル",
                address: "〒220-8173　横浜市西区みなとみらい2-2-1-3",
                link: "https://www.yrph.com/"
              },
              {
                name: "ザ ロイヤルパークホテル アイコニック 東京汐留",
                address: "〒105-8333　東京都港区東新橋1-6-3",
                link: "https://www.royalparkhotels.co.jp/ic/tokyoshiodome/"
              },
              {
                name: "ザ ロイヤルパークホテル 東京羽田",
                address: <>〒144-0041　東京都大田区羽田空港2-6-5　<br className="u_sp" />羽田空港第3旅客ターミナルビル内</>,
                link: "https://www.royalparkhotels.co.jp/the/tokyohaneda/"
              },
              {
                name: "ザ ロイヤルパークホテル 銀座6丁目",
                address: "〒104-0061　東京都中央区銀座6-16-14",
                link: "https://www.royalparkhotels.co.jp/the/ginza6/"
              },
              {
                name: "ザ ロイヤルパーク キャンバス 銀座コリドー",
                address: "〒104-0061　東京都中央区銀座6-2-11",
                link: "https://www.royalparkhotels.co.jp/canvas/ginzacorridor/"
              },
              {
                name: "丸ノ内ホテル",
                address: "〒100-0005　東京都千代田区丸の内1-6-3",
                link: "https://www.marunouchi-hotel.co.jp/"
              },

            ]} />


            <div className="r-about-hotel-name">名古屋エリア</div>
            <CHotelList addresses={[
              {
                name: "ザ ロイヤルパークホテル アイコニック 名古屋 ",
                address: "〒460-0008　名古屋市中区栄4-1-1　中日ビル24F",
                link: "https://www.royalparkhotels.co.jp/ic/nagoya/"
              },
              {
                name: "ザ ロイヤルパーク キャンバス 名古屋",
                address: "〒450-0002　名古屋市中村区名駅3-23-13",
                link: "https://www.royalparkhotels.co.jp/canvas/nagoya/"
              }

            ]} />


            <div className="r-about-hotel-name">京都・大阪・神戸エリア</div>
            <CHotelList addresses={[
              {
                name: "ザ ロイヤルパークホテル アイコニック 京都",
                address: "〒604-0836　京都市中京区船屋町420",
                link: "https://www.royalparkhotels.co.jp/ic/kyoto/"
              },
              {
                name: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋",
                address: <>〒541-0046　大阪市中央区平野町4-2-3　<br className="u_sp" />オービック御堂筋ビル</>,
                link: "https://www.royalparkhotels.co.jp/ic/osakamidosuji/"
              },
              {
                name: "ザ ロイヤルパークホテル 京都三条",
                address: "〒604-8004　京都市中京区中島町74",
                link: "https://www.royalparkhotels.co.jp/the/kyotosanjo/"
              },
              {
                name: "ザ ロイヤルパークホテル 京都梅小路",
                address: "〒600-8835　京都市下京区観喜寺町9-3",
                link: "https://www.royalparkhotels.co.jp/the/kyotoumekoji/"
              },
              {
                name: "ザ ロイヤルパーク キャンバス 京都二条",
                address: "〒604-8381　京都市中京区西ノ京職司町22-7",
                link: "https://www.royalparkhotels.co.jp/canvas/kyotonijo/"
              },
              {
                name: "ザ ロイヤルパーク キャンバス 大阪北浜",
                address: "〒541-0041　大阪市中央区北浜1-9-8",
                link: "https://www.royalparkhotels.co.jp/canvas/osakakitahama/"
              },
              {
                name: "ザ ロイヤルパーク キャンバス 神戸三宮",
                address: "〒650-0011　神戸市中央区下山手通2-3-1",
                link: "https://www.royalparkhotels.co.jp/canvas/kobesannomiya/"
              },

            ]} />

            <div className="r-about-hotel-name">広島エリア</div>
            <CHotelList addresses={[
              {
                name: "ザ ロイヤルパークホテル 広島リバーサイド",
                address: "〒730-0014　広島市中区上幟町7-14",
                link: "https://www.royalparkhotels.co.jp/the/hiroshimars/"
              }
            ]} />

            <div className="r-about-hotel-name">福岡エリア</div>
            <CHotelList addresses={[
              {
                name: "ザ ロイヤルパークホテル 福岡",
                address: "〒812-0011　福岡市博多区博多駅前2-14-15",
                link: "https://www.royalparkhotels.co.jp/the/fukuoka/"
              },
              {
                name: "ザ ロイヤルパーク キャンバス 福岡中洲",
                address: "〒810-0801　福岡市博多区中洲5-6-20",
                link: "https://www.royalparkhotels.co.jp/canvas/fukuokanakasu/"
              }
            ]} />

            <div className="r-about-hotel-name">沖縄エリア</div>
            <CHotelList addresses={[
              {
                name: "ホテルアトールエメラルド宮古島",
                address: "〒906-0013　沖縄県宮古島市平良字下里108-7",
                link: "https://www.atollemerald.jp/"
              }
            ]} />


          </div>
        </LWrap2>
      </section>
    </LayoutSl>

  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
